/** @jsx jsx */
import { MDXRenderer } from 'gatsby-plugin-mdx';
import get from 'lodash/get';
import { jsx, Styled } from 'theme-ui';

// @ts-ignore
import CoverImage from 'gatsby-theme-chronoblog/src/components/cover-image';
import Date from '../date';
import Layout from 'gatsby-theme-chronoblog/src/components/layout';
import SEO from 'gatsby-theme-chronoblog/src/components/seo';
import Tags from 'gatsby-theme-chronoblog/src/components/tags';

const PostTitle = ({
  data: {
    mdx: { frontmatter }
  }
}) => {
  return (
    <div>
      {frontmatter.title ? <Styled.h1>{frontmatter.title}</Styled.h1> : ''}
    </div>
  );
};

const PostContent = ({
  data: {
    mdx: { body }
  }
}) => {
  return <MDXRenderer>{body}</MDXRenderer>;
};

const getDescriptionForSeo = (fromFrontmatter, fromExcerpt) => {
  if (fromFrontmatter && fromFrontmatter !== '') return fromFrontmatter;
  if (fromExcerpt && fromExcerpt !== '') return fromExcerpt;
  return '';
};

export const Note = ({ data }) => {
  const description = getDescriptionForSeo(
    data.mdx.frontmatter.description,
    data.mdx.excerpt
  );
  const imagePath = get(
    data,
    'mdx.frontmatter.cover.childImageSharp.fluid.src',
    ''
  );
  const updatedAt = get(data, 'mdx.parent.fields.gitLogLatestDate', '');
  //
  return (
    <Layout>
      <SEO
        title={data.mdx.frontmatter.title}
        slug={data.mdx.fields.slug}
        description={description}
        image={imagePath}
      />
      <main>
        <article>
          <header>
            <CoverImage data={data.mdx} type="post" />
            <PostTitle data={data} />
            Published <Date date={data.mdx.frontmatter.date} />・
            {updatedAt && (
              <span>
                Last updated <Date date={updatedAt} isUpdatedAt />・
              </span>
            )}
            <span
              sx={{
                color: 'text',
                opacity: 0.8,
                // fontStyle: 'italic',
                fontWeight: 'normal'
              }}
            >
              {data.mdx.fields.readingTime.text}
            </span>
          </header>
          <PostContent data={data} />
          <footer sx={{ marginTop: '20px' }}>
            <Tags type="item" tags={data.mdx.frontmatter.tags} />
          </footer>
        </article>
      </main>
    </Layout>
  );
};

export default Note;

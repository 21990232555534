import { graphql } from 'gatsby';

import Note from 'gatsby-theme-chronoblog/src/components/note';

export default Note;

export const noteQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      parent {
        ... on File {
          fields {
            gitLogLatestDate
          }
        }
      }
      id
      excerpt
      frontmatter {
        title
        date
        job_ad
        job_ad_id
        job_ad_url
        tags
        cover {
          childImageSharp {
            fluid(maxWidth: 768, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
              src
            }
            resize(width: 768) {
              src
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
      body
    }
  }
`;
